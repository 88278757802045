<div class="page-container product-shelf-container container">
  <form #rixtremaForm>
    <h1 class="w-100">Lookup Plan</h1>
    <div class="row">
      <mat-form-field class="col-6">
        <input
          matInput
          [(ngModel)]="searchTerm"
          id="rixtrema-search"
          placeholder="Enter Plan Name"
          name="searchTerm"
        />
      </mat-form-field>
      <div class="col-6"> </div>
      <mat-form-field class="col-6">
        <input
          matInput
          [(ngModel)]="zip"
          id="zip"
          placeholder="ZIP"
          name="zip"
        />
      </mat-form-field>
      <mat-form-field class="col-6">
        <input
          matInput
          [(ngModel)]="distance"
          id="distance"
          placeholder="Distance"
          name="distance"
        />
      </mat-form-field>
      <mat-form-field class="col-6">
        <input
          matInput
          [(ngModel)]="participantMin"
          id="participantMin"
          placeholder="Participant Min"
          name="Participant Min"
        />
      </mat-form-field>
      <mat-form-field class="col-6">
        <input
          matInput
          [(ngModel)]="participantMax"
          id="participantMax"
          placeholder="Participant Max"
          name="Participant Max"
        />
      </mat-form-field>
      <mat-form-field class="col-6">
        <input
          matInput
          [(ngModel)]="feeMin"
          id="feeMin"
          placeholder="Fee Min"
          name="Total Fee Min"
        />
      </mat-form-field>
      <mat-form-field class="col-6">
        <input
          matInput
          [(ngModel)]="feeMax"
          id="feeMax"
          placeholder="Fee Max"
          name="Total Fee Max"
        />
      </mat-form-field>
      <div mat-dialog-actions class="buttons col-6 leftButton">
        <button
          mat-raised-button
          (click)="search(); $event.preventDefault()"
          [disabled]="!searchValid()"
          >Search</button
        >
      </div>
    </div>
  </form>
  <h1 class="header col-12">Product Shelf</h1>
  <kendo-grid
    class="col-12"
    #productGrid="kendoGrid"
    [kendoGridBinding]="gridData.data"
    (pageChange)="pageChange($event)"
    [skip]="state.skip"
    [pageSize]="state.take"
    [pageable]="true"
    [sortable]="{ allowUnsort: true, mode: 'multiple' }"
    [sort]="state.sort"
    [filterable]="'menu'"
    [columnMenu]="true"
    [filter]="state.filter"
    [groupable]="true"
    [group]="state.group"
  >
    <kendo-grid-column>
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          mat-icon-button
          class="fs-button"
          (click)="dlFactsheet(dataItem)"
        >
          <mat-icon>insert_drive_file</mat-icon>
        </button>
        <button
          mat-icon-button
          class="fs-button"
          (click)="createNewCase(dataItem)"
        >
          <mat-icon>open_in_new</mat-icon>
        </button>
        <mat-checkbox
          [ngModelOptions]="{ standalone: true }"
          (click)="compare(dataItem)"
        >
        </mat-checkbox>
      </ng-template>
    </kendo-grid-column>
    @for (column of gridData.headers; track column) {
      <kendo-grid-column
        [field]="column.DataField"
        [hidden]="isHidden(column.DataField)"
        [title]="column.Label"
        [sortable]="column.Sort"
        [filterable]="column.Filter"
        [groupable]="column.Group"
        [media]="column.RespVis"
      >
      </kendo-grid-column>
    }
    @if (gridData['subHead']) {
      <ng-template kendoGridDetailTemplate let-dataItem>
        <!--  -->
        <!-- Have to make specific tabs to force order and not have multiple rate summary sections show up and teh ability to show messages for empty datasets -->
        <!-- Good code is commented out below... -->
        <!--  -->
        <mat-tab-group mat-stretch-tabs class="w-100">
          @for (tab of gridData.subHead | keyvalue; track tab) {
            @if (tab.key === 'riders') {
              <app-product-detail-tab
                [label]="'Income Riders'"
                [dataItem]="dataItem"
                [tab]="tab"
                [noResultsType]="'riders'"
              ></app-product-detail-tab>
            }
            @if (tab.key === 'deathRiders') {
              <app-product-detail-tab
                [label]="'Death Benefit Riders'"
                [dataItem]="dataItem"
                [tab]="tab"
                [noResultsType]="'death benefit riders'"
              ></app-product-detail-tab>
            }
            @if (
              tab.key === 'fiaRates' &&
              dataItem.annuityType.toLowerCase() === 'ia'
            ) {
              <app-product-detail-tab
                [label]="'Rates Summary'"
                [dataItem]="dataItem"
                [tab]="tab"
                [noResultsType]="'rates'"
              ></app-product-detail-tab>
            }
            @if (
              tab.key === 'faRates' &&
              dataItem.annuityType.toLowerCase() === 'fa'
            ) {
              <app-product-detail-tab
                [label]="'Rates Summary'"
                [dataItem]="dataItem"
                [tab]="tab"
                [noResultsType]="'rates'"
              ></app-product-detail-tab>
            }
            @if (
              tab.key === 'viaRates' &&
              dataItem.annuityType.toLowerCase() === 'rila'
            ) {
              <app-product-detail-tab
                [label]="'Rates Summary'"
                [dataItem]="dataItem"
                [tab]="tab"
                [noResultsType]="'rates'"
              ></app-product-detail-tab>
            }
            @if (tab.key === 'funds' && dataItem[tab.key].length > 0) {
              <app-product-detail-tab
                [label]="'Investment Options'"
                [dataItem]="dataItem"
                [tab]="tab"
              ></app-product-detail-tab>
            }
          }
        </mat-tab-group>
      </ng-template>
    }
  </kendo-grid>
</div>
<ngx-loading [show]="loading"></ngx-loading>
